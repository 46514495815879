.post_audience_page .card {
  padding: 0.5em;
  padding-top: 2em;
  display: flex;
  flex-direction: column;
}

.post_audience_page .dialog > section {
  bottom: 8em;
}

.post_audience_page .options {
  display: flex;
  flex-direction: column;
  margin: 0.5em;
}

.post_audience_page .radio_option {

}

.post_audience_page .group_info {
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em;
  border: 0.01em solid #999999;
  display: flex;
}

@media screen and (min-width: 600px) {
  .post_audience_page .group_info {
    flex-direction: row;
  }
}

@media screen and (max-width: 599px) {
  .post_audience_page .group_info {
    flex-direction: column;
  }
}

.post_audience_page .group_summary {
  display: flex;
  flex-direction: row;
  flex: 10;
  overflow-x: hidden;
}

.post_audience_page .button {
  flex: 1;
}

.post_audience_page .publishing {
  padding-left: 0.5em;
  padding-right: 0.5em;
  align-self: center;
  opacity: 0.5;
}

.post_audience_page .publish {
  padding-left: 0.5em;
  padding-right: 0.5em;
  align-self: center;
}
